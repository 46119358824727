import { TrendingCoinInfoDto } from '@/services/models/TrendingCoinInfoDto';

export enum SortableColumnsTrending {
  price24HChange = 'price_24h_change',
  fullyDilutedValuation = 'fully_diluted_valuation',
  coinAge = 'coin_age',
  volume24H = 'volume_24h',
  buyPressure = 'buy_pressure',
  twitterFollowerCount = 'twitter_follower_count',
  moralisScore = 'moralis_score',
}

const enumValues = Object.values(SortableColumnsTrending);
const isValidColNameTrending = (column: string): column is SortableColumnsTrending =>
  enumValues.includes(column as SortableColumnsTrending);

export const fromQueryToTrendingColumnName = (col: string | null) =>
  col && isValidColNameTrending(col) ? col : SortableColumnsTrending.price24HChange;

const assertExhaustive = (x: never): never => {
  throw new Error(`Unexpected value of SortableColumnsTrending ${x}`);
};
export const getSortableTrendingTokenValue = (column: SortableColumnsTrending, it: TrendingCoinInfoDto) => {
  switch (column) {
    case SortableColumnsTrending.buyPressure:
      return it.buyPressure;
    case SortableColumnsTrending.coinAge:
      return it.blockTimestamp;
    case SortableColumnsTrending.fullyDilutedValuation:
      return it.fullyDilutedValuation;
    case SortableColumnsTrending.moralisScore:
      return it.moralisScore;
    case SortableColumnsTrending.price24HChange:
      return it.price24HChange;
    case SortableColumnsTrending.twitterFollowerCount:
      return it.twitterFollowerCount;
    case SortableColumnsTrending.volume24H:
      return it.volume24H;
    default:
      return assertExhaustive(column);
  }
};
