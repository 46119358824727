import { EHomePageView } from '@/components/common/HomePage/lib/EHomePageView.enum';
import { useAdsView } from '@/helpers/hooks/useAdsView';
import { TrendingCoinInfoDto } from '@/services';
import { ChainDataSummaryDto } from '@/services/models/ChainDataSummaryDto';
import { DataSummaryVolume } from '@/utils/api/ssr/fetchChainsDataSummary';
import { SortedCategoriesProps } from '@/utils/api/ssr/getSortedCategoriesByVolume';
import { Advertisement, TGlossaryItem, TTokenStrategiesDataResponsePartial } from '@/utils/contentful/type';
import { HomeTokenAdBanner } from '../ads/homeTokenAd/HomeTokenAdBanner';
import { TrendingPageUI } from './TrendingPageUI';
import { useTrending } from './useTrending';

export const TrendingPage = ({
  chainId,
  category,
  glossaryMoneyMetricTerms,
  categoryTitle,
  chainTitle,
  contentfulTokenDataStrategies,
  ads,
  sortedCategories,
  dataSummaryTokens,
  dataSummaryTotalVolume,
  dataSummaryNetVolume,
  isTrendingTokenDataLoading,
  trendingTokenData,
}: {
  chainId: string | null;
  category: string | null;
  glossaryMoneyMetricTerms: TGlossaryItem[];
  categoryTitle?: string;
  chainTitle?: string;
  contentfulTokenDataStrategies?: TTokenStrategiesDataResponsePartial[];
  ads: Advertisement[];
  sortedCategories: SortedCategoriesProps;
  dataSummaryTokens: ChainDataSummaryDto;
  dataSummaryTotalVolume: DataSummaryVolume[];
  dataSummaryNetVolume: DataSummaryVolume[];
  isTrendingTokenDataLoading?: boolean;
  trendingTokenData: TrendingCoinInfoDto[];
}) => {
  const { currentPageItems, vars, hasMoreData, updateCurrentPageIndex, toggleSorting, updateCategory, updateChainId } =
    useTrending({ trendingTokenData, defaultChainId: chainId, defaultCategory: category });

  const { tokenAdsView, tokenAdsViewLoading } = useAdsView('trending');

  const isLoading = isTrendingTokenDataLoading || Boolean(!currentPageItems.length && trendingTokenData.length);

  return (
    <div>
      <HomeTokenAdBanner tokenAdsView={tokenAdsView} isLoading={tokenAdsViewLoading} />

      <TrendingPageUI
        ads={ads}
        currentPage={vars.currentIndex + 1}
        itemsPerPage={vars.itemsPerPage}
        tabId={EHomePageView.TRENDING}
        trendingTokenPage={currentPageItems}
        onSortChange={toggleSorting}
        sortingColumn={vars.sortingColumn}
        sortingOrder={vars.sortingOrder}
        onChangePageNumber={updateCurrentPageIndex}
        category={vars.category}
        onChangeCategory={updateCategory}
        chainId={vars.chainId}
        onChangeChainId={updateChainId}
        selectedFiltersCount={vars.filtersCount}
        glossaryMoneyMetricTerms={glossaryMoneyMetricTerms}
        categoryTitle={categoryTitle}
        chainTitle={chainTitle}
        contentfulTokenDataStrategies={contentfulTokenDataStrategies}
        sortedCategories={sortedCategories}
        dataSummaryTokens={dataSummaryTokens}
        dataSummaryTotalVolume={dataSummaryTotalVolume}
        dataSummaryNetVolume={dataSummaryNetVolume}
        hasMoreData={hasMoreData}
        isTrendingTokenDataLoading={isLoading}
      />
    </div>
  );
};
