import { useEffect, useMemo, useState } from 'react';
import { useDefaultNavigator } from '@/helpers/hooks/useDefaultNavigator';
import { useTableDataStateURL } from '@/helpers/hooks/useTableDataStateURL';
import { TrendingCoinInfoDto } from '@/services/models/TrendingCoinInfoDto';
import { logApp } from '@/utils/logApp';
import { sortAsNumbers } from '@/utils/sorting';
import { fromQueryToTrendingColumnName, getSortableTrendingTokenValue } from '@/utils/trending/trendingTokens';
import { DefaultIDs, useTrendingFilters } from './useTrendingFilters';

const log = logApp.create('useTrending');
export const ITEMS_PER_PAGE = 20;
export const COLUMNS_PER_ROW = 9;

export const useTrending = ({
  trendingTokenData,
  defaultChainId,
  defaultCategory,
}: DefaultIDs & { trendingTokenData: TrendingCoinInfoDto[]; isTrendingTopbar?: boolean }) => {
  const { pathname, navigate } = useDefaultNavigator();
  const filters = useTrendingFilters({
    defaultChainId,
    defaultCategory,
  });

  const tds = useTableDataStateURL({
    fromQuery: fromQueryToTrendingColumnName,
    totalLength: trendingTokenData.length,
    itemsPerPage: ITEMS_PER_PAGE,
  });

  const sortedData = useMemo(
    () =>
      sortAsNumbers(
        [...trendingTokenData],
        (it) => getSortableTrendingTokenValue(tds.tableSort.column, it),
        tds.tableSort.order,
      ),
    [trendingTokenData, tds.tableSort.column, tds.tableSort.order, filters.chainId],
  );

  const [currentPageItems, setCurrentPageItems] = useState<TrendingCoinInfoDto[]>(sortedData.slice(0, ITEMS_PER_PAGE));

  useEffect(() => {
    log.debug('useEffect', { fromFilter: filters.currentPath, pathname });
    if (filters.currentPath === pathname) {
      return;
    }
    navigate({
      pathname: filters.currentPath,
      searchString: tds.updateCurrentPageIndex(0),
    });
  }, [filters.currentPath, pathname, navigate, tds, log]);

  const toggleSorting: (...a: Parameters<typeof tds.toggleSorting>) => void = (col) => {
    navigate({
      pathname: filters.currentPath,
      searchString: tds.toggleSorting(col),
    });
  };

  const handleAppendNextItemsToCurrentPage = () => {
    const nextItemsStartIndex = currentPageItems.length;
    const nextItemsEndIndex = nextItemsStartIndex + ITEMS_PER_PAGE;
    const nextItems = sortedData.slice(nextItemsStartIndex, nextItemsEndIndex);

    if (nextItems.length === 0) return;

    setCurrentPageItems((prevItems) => [...prevItems, ...nextItems]);

    tds.updateCurrentPageIndex(tds.tablePage.currentIndex + 1);
  };

  useEffect(() => {
    // Update the current page items with the filtered data
    setCurrentPageItems(sortedData.slice(0, ITEMS_PER_PAGE));

    // Reset the page index
    tds.updateCurrentPageIndex(0);
  }, [sortedData, tds.tableSort.order, tds.tableSort.column]);

  return {
    currentPageItems,
    vars: {
      filtersCount: filters.filtersCount,
      chainId: filters.chainId,
      category: filters.category,
      pageIndex: tds.tablePage.currentIndex,
      sortingColumn: tds.tableSort.column,
      sortingOrder: tds.tableSort.order,
      ...tds.tablePage,
    },
    updateCategory: filters.updateCategory,
    updateChainId: filters.updateChainId,
    updateCurrentPageIndex: handleAppendNextItemsToCurrentPage,
    toggleSorting,
    hasMoreData: currentPageItems.length < sortedData.length,
  };
};
