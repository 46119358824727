import { TrendingCoinInfoDto } from '@/services';
import { fetchJSON } from '@/utils/api/fetchJSON';

export const fetchTrendingTokens = async (slug: string = '') => {
  const baseUrl = '/api/trending-tokens';
  const url = slug ? `${baseUrl}/${slug}` : baseUrl;
  const { coins } = await fetchJSON({
    url,
    response: fetchJSON.as<{
      coins: TrendingCoinInfoDto[];
      title: string;
      description: string;
    }>,
  });

  return coins;
};
